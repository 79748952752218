import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

export const Pagination1 = (props) => {
  const { onChangePageClick, currentPageNo, count, onPageSizeChange, currentPageSize, activePageTabItem, listData, checkedList, toggleIsOpenAssigneeFormPopover, getCount, isHideShowingDiv ,classes, bottom,pos} = props;
  let [pageNoText, setPageNoText] = useState(0);
  let [pageSize, setPageSize] = useState(currentPageSize);
  let [isShowingDiv, setShowinDiv] = useState(true);
  console.log(currentPageSize,'currentPageSizecurrentPageSize', currentPageNo);

  // let [isTotalVisible, setTotalVisible] = useState(false);
  // let [total, setTotal] = useState('');

  // useEffect(async () => {
  // if (!Number(count)) count = 0;
  // }, []);
  useEffect(async () => {
    if (isHideShowingDiv) {
      setShowinDiv(false);
    } else {
      setShowinDiv(true);
    }
  }, []);

  /*useEffect(() => {
    // setTotalVisible(false);
    // pageSize = 5;
    onPageSizeChange(currentPageSize);
    setPageSize(currentPageSize);
  },[activePageTabItem]);*/

  function onPreviousPageClick(e) {
    e.preventDefault();
    onChangePageClick(Number(currentPageNo) - 1);
  }

  function onNextPageClick(e) {
    e.preventDefault();
    onChangePageClick(Number(currentPageNo) + 1);
  }

  function onPageSizeClick(e) {
    pageSize = e.target.value;
    onPageSizeChange(pageSize);
    setPageSize(pageSize);
  }

  /*async function toggleTotal(e) {
    e.preventDefault();
    total = await getCount();
    setTotal(total);
    setTotalVisible(true);
  }*/
  /*
    Modification: Add condition for Classes
    By: komal wable
    Date: 17/03/2023
  */

  return (
    <div class={`${classes ? 'w-81-p bottom-69px m-l-270':''} pagination-block ${pos ? 'pos-none' : 'pos-absolute '} row`} style={`bottom: ${bottom ? bottom : 0};`}>
      {/*to add margine to to of select select is touching to list line so to show gap added margine top */}
      <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 p-0 display-flex flex-direction-row align-center justify-flex-start m-t-10">
        <p class="table-title" style="z-index : 0">RECORDS: </p>
        <select name="records" id="records" onchange={(e) => onPageSizeClick(e)}>
          <option value="5" selected={pageSize === 5}>5</option>
          <option value="10" selected={pageSize === 10}>10</option>
          <option value="20" selected={pageSize === 20}>20</option>
          <option value="50" selected={pageSize === 50}>50</option>
          <option value="100" selected={pageSize === 100}>100</option>
          <option value="200" selected={pageSize === 200}>200</option>
          <option value="500" selected={pageSize === 500}>500</option>
        </select>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 p-0 bulk-actions">
        {
          checkedList && checkedList.length > 0 &&
          <div class="bulk-action-btn">
            <svg class="cursor-pointer" onClick={toggleIsOpenAssigneeFormPopover} xmlns="http://www.w3.org/2000/svg" width="28px" height="28px" viewBox="0 0 36 36"
              version="1.1" preserveAspectRatio="xMidYMid meet"
            > <title>Assign User</title> <path d="M18,17a7.46,7.46,0,1,0-7.45-7.46A7.46,7.46,0,0,0,18,17ZM18,4.07a5.46,5.46,0,1,1-5.45,5.45A5.46,5.46,0,0,1,18,4.07Z" class="clr-i-outline clr-i-outline-path-1" /><path d="M6,31.89V25.77a16.13,16.13,0,0,1,12-5,16.61,16.61,0,0,1,8.71,2.33l1.35-1.51A18.53,18.53,0,0,0,18,18.74,17.7,17.7,0,0,0,4.21,24.8a1,1,0,0,0-.21.6v6.49A2.06,2.06,0,0,0,6,34H18.39l-1.9-2Z" class="clr-i-outline clr-i-outline-path-2" /><path d="M30,31.89,30,32H26.85l-1.8,2H30a2.06,2.06,0,0,0,2-2.07V26.2l-2,2.23Z" class="clr-i-outline clr-i-outline-path-3" /><path d="M34.76,18.62a1,1,0,0,0-1.41.08l-11.62,13-5.2-5.59A1,1,0,0,0,15.12,26a1,1,0,0,0-.06,1.42l6.69,7.2L34.84,20A1,1,0,0,0,34.76,18.62Z" class="clr-i-outline clr-i-outline-path-4" /> <rect x="0" y="0" width="36" height="36" fill-opacity="0" /></svg>
          </div>
        }
      </div>
      <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 p-0 display-flex flex-direction-row justify-flex-end">
        <div class="flex-c-m m-r-16 fs-12 h-full">
          {
            isShowingDiv && (
              `Showing ${(pageSize* (currentPageNo - 1)) + 1} to ${(pageSize* (currentPageNo - 1)) +  listData.length} of ${count} entries`
            )
          }
        </div>
        <div class="flex-direction-row flex-c-m h-full">
          <p class="table-title">Pages: {currentPageNo} / {Math.ceil(count / pageSize)}</p>
          <button class="table-title no-padding cursor-pointer" onClick={(e) => onPreviousPageClick(e)}
            disabled={(currentPageNo <= 1) || (pageNoText === 1)}
          >
            <img class="w-12px h-auto" src="/assets/images/prevPage.svg" />
          </button>
          <button class="table-title no-padding cursor-pointer" onClick={(e) => onNextPageClick(e)}
            disabled={(currentPageNo === (Math.ceil(count / pageSize))) || (pageNoText === (Math.ceil(count / pageSize)))}
          >
            <img class="w-12px h-auto" src="/assets/images/nextPage.svg" />
          </button>
        </div>
      </div>
      {/*<form style="display:contents">
        <table >
          <tbody>
            <tr>
              <th>
                <span class="table-title">CHECKED: 0/5</span>
              </th>
              <th>
                {isTotalVisible && (
                  <span class="table-title">TOTAL: {total}</span>
                )}
                {!isTotalVisible && (
                  <span class="table-title">TOTAL: <span class="total" onclick={(e) => toggleTotal(e)}>Show Quantity</span></span>
                )}
              </th>
              <th>
                <div style="display: flex;">
                  <span class="table-title">Pages: {currentPageNo} / {Math.ceil(count / pageSize)}</span>
                  <button class="table-title no-padding cursor-pointer" onClick={(e) => onPreviousPageClick(e)}
                    disabled={(currentPageNo <= 1) || (pageNoText === 1)} >
                    <img class="w-12px h-auto" src="/assets/images/prevPage.svg" />
                  </button>
                  <button class="table-title no-padding cursor-pointer" onClick={(e) => onNextPageClick(e)}
                    disabled={(currentPageNo === (Math.ceil(count / pageSize))) || (pageNoText === (Math.ceil(count / pageSize)))} >
                    <img class="w-12px h-auto" src="/assets/images/nextPage.svg" />
                  </button>
                </div>
              </th>
              <th>
                <div class="flex-c-m float-right">
                  <span class="table-title">RECORDS: </span>
                  <select name="records" id="records" onchange={(e) => onPageSizeClick(e)}>
                    <option value="5" selected={pageSize === 5}>5</option>
                    <option value="10" selected={pageSize === 10}>10</option>
                    <option value="20" selected={pageSize === 20}>20</option>
                    <option value="50" selected={pageSize === 50}>50</option>
                    <option value="100" selected={pageSize === 100}>100</option>
                    <option value="200" selected={pageSize === 200}>200</option>
                    <option value="500" selected={pageSize === 500}>500</option>
                  </select>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
      </form>*/}
    </div>
  );
};
